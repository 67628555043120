import './bootstrap';
import guideModal from './components/guideModal';
import.meta.glob([
    '../images/**/*.{png,jpg,jpeg,gif,webp,svg}',
]);

// import * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn: "https://b0e2bebc4e7739a6584ce664073b5a33@o423412.ingest.us.sentry.io/4507923879034880",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

Alpine.data('guideModal', guideModal);
